import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Patient Testimonials – Cosmetic Dentist in Toronto | The Smile Stylist" 
    description="Our patients results and experiences mean everything to us! Here is what our patients had to say regarding their porcelain veneers and cosmetic dentistry results." 
    keywords="cosmetic dentist toronto" />
    <div class="test__popup">
    <div class="full-height row">
      <div class="full-height col-sm-6">
        <div class="test__top-row">
          <img class="test__image-logo" src={ require("../images/desktop/ss-white.png" )} alt="" />
        </div>
        <div class="test__image-left"></div>
      </div>
      <div class="full-height col-sm-6">
        <div class="test__image-right"></div>
        <div class="test__bottom-row">
          <div>
            <p class="test__title-bottom">Financing Your Smile</p>
            <p class="test__subtitle">Ensures that you don’t delay your treatment<br />due to cost concerns. </p>
            <a href="/financing" class="test__btn">Learn More</a>
          </div>
        </div>
      </div>
      <span class="test__close-btn">X</span>
    </div>
  </div>
  <main class="site-content" role="main">
    <section class="test __no-pad">
      <div class="test__cont container">
        <h1 class="test__title">What Our Patients Are Saying</h1>
      </div>
      <img class="test__img" src={ require("../images/desktop/parallax/test.png" )} alt="Patients Testimonial " />
    </section>

    <section id="main-white">
      <div class="container">
        <div class="test__review">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/porcelain-veneers" class="test__service">Porcelain Veneers</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Incredibly professional team.</p>
          </div>
          <div class="row">
            <p class="test__message">Dr. Kaloti and his team (Rebecca is the sweetest dental hygienist) are incredible at what they do. It’s a warm and vibrant atmosphere, where you’ll feel comfortable in talking about your concerns with your teeth. And Dr. Kaloti is a great listener, attentive and thoughtful. Going to Dr. Kaloti for my veneers was the best decision I’ve ever made for myself. Many more visits to come.</p>
          </div>
          <div class="row">
            <p class="test__date">Christina Tran - 16 Apr 2018</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/porcelain-veneers" class="test__service">Porcelain Veneers</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Best dentist I've ever met</p>
          </div>
          <div class="row">
            <p class="test__message">Suffering discoloured teeth from tetracycline for many years caused me feeling very insecure about my looks and having a very low self-esteem. After research, I gave Dr Kaloti's office a try with a free consultation. His professional and confidence made me trust him that he's the one who will give me back my whiter smile. Thank you so much for the amazing work that you and your wonderful staffs did for me. Highly recommended!</p>
          </div>
          <div class="row">
            <p class="test__date">Le Dang - 27 Dec 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/porcelain-veneers" class="test__service">Porcelain Veneers</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">I can't even begin to tell you how much I love The Smile Stylist</p>
          </div>
          <div class="row">
            <p class="test__message">From the warm welcome you receive when you enter the building to the ridiculously professional service he always provides, Dr. Kolati and his incredible staff are the epitome of dental perfection. The boutique style allows you to really get to know all the professionals around you and, in turn, they get to know you so well. It really is like a little family that makes visiting the dentist a total joy.</p>
            <p class="test__message">The best part about it all though is that not only are the people great, but the services are beyond exceptional. As a dental artist Dr. Kaloti, The Smile Stylist, not only masters the technicals of teeth, but he also masters the aesthetic aspect to a total T. As someone who relies on her smile daily, I couldn't imagine trusting my teeth to anyone else.</p>
          </div>
          <div class="row">
            <p class="test__date">Ashley J. Hassard - 18 Dec 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/in-office-bonding" class="test__service">Dental Bonding</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Amazing experience</p>
          </div>
          <div class="row">
            <p class="test__message">I had a very embarrassing situation and I needed to see a dentist as soon as possible. So I went on line and found Doctor Kaloti's office. Unfortunately, , the doctor was away and would not be back fior a few days. I spoke to Rebecca who was an amazing help, she temporarily fixed the problem until I am able to see the doctor for a more permanent solution. She did an amazing job of which I am so grateful for. Now, I am looking forward to going back there to see Dr Kaloti. Thanks Rebecca for excellent hospitality, you did an amazing job. Keep it up!</p>
          </div>
          <div class="row">
            <p class="test__date">Sade - 16 Aug 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/full-mouth-reconstruction" class="test__service">Dental Implants</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Best dentist I've been to in years!!</p>
          </div>
          <div class="row">
            <p class="test__message">I came to Dr. Kaloti's office after receiving an offer in the mail for a free consultation. I was very impressed with the time he spent explaining everything that needed to be done regarding dental implants. I have already been through one procedure and will have more done in the fall. All went well and I'm very happy with the work he did. The staff and Dr. Kaloti are very personable and welcoming. A special shout out to Rebecca. Very funny lady! I actually look forward to seeing them again in the fall.</p>
          </div>
          <div class="row">
            <p class="test__date">Abbe Osicka - 9 Jul 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/ceramic-crowns" class="test__service">Dental Crown</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">I had an awesome experience</p>
          </div>
          <div class="row">
            <p class="test__message">I had an amazing experience! It started with a route canal and ended with a Crown on my front tooth and also whitening to have an even smile. The whole staff was a pleasure to work with but more importantly, my crown turned out perfect!! I'm very happy with the results.</p>
          </div>
          <div class="row">
            <p class="test__date">ftrich01 - 22 Jun 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/porcelain-veneers" class="test__service">Porcelain Veneers</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">If I could give Dr. Kaloti more stars I would.</p>
          </div>
          <div class="row">
            <p class="test__message">Dr. Kaloti is one of the most genuine and talented people I have come across and that really shines through during the initial consultation. His attention to detail and ability to communicate make the whole experience enjoyable and his skill set is just remarkable. I think what really surprised me is the passion and excitement he has for what he does and how happy it makes him to be able to really transform your life. I could not be more impressed.</p>
          </div>
          <div class="row">
            <p class="test__date">JC- 18 Jun 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/full-mouth-reconstruction" class="test__service">Dental Bone Graft</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">One of the Best Dentist's I've ever had.</p>
          </div>
          <div class="row">
            <p class="test__message">Absolutely first rate. Painless and quick work. Explained to me every part of my dental procedure, the likelihood of pain, what he will prescribe by way of medicine's and the length of time I would have the discomfort. The surgery and extraction was explained in layman's terms as to why it is needed and what took place for me to need this procedure. Dr. Kaloti was friendly and humorous the whole time I was in the chair and I left feeling he genuinely cared about my well being. </p>
          </div>
          <div class="row">
            <p class="test__date">Jym G - 22 May 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/teeth-whitening" class="test__service">Clear Correct</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">My Clear Correct Experience</p>
          </div>
          <div class="row">
            <p class="test__message">I am now on final stages and I couldn't be more impressed with my transformation.
              I started to see improvements about one month after I started and from there I see improvements every 3 weeks. Most people didn't notice I had my trays in unless I told them. I found it easy to pop them in and out to eat and them clean. The first 2 days of every new tray was the tightest feeling but then it felt comfortable</p>
          </div>
          <div class="row">
            <p class="test__date">Maria Dapaz - 19 Dec 2016</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/gum-lift-procedures" class="test__service">Gum Lift</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Outstanding treatment delivered in an efficient and friendly way</p>
          </div>
          <div class="row">
            <p class="test__message">Doctor Kaloti created a very positive mindset right from diagnosis to conclusion of treatment. I understood the need and what had to be done. The process was efficient, virtually pain free. He proactively addressed all the potential issues that could arise following the procedure, so there were no complications. The effective treatment described above was all efficiently delivered in a very friendly and sensitive manner, resulting a very comfortable experience overall.</p>
          </div>
          <div class="row">
            <p class="test__date">Gil Raine - 14 Dec 2016</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/ceramic-crowns" class="test__service">Dental Crown</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Painless for a big chicken</p>
          </div>
          <div class="row">
            <p class="test__message">I grew up avoiding the dentist at all costs. I was terrified of walking into a dental office let alone sitting in a dental chair! After I could not procastinate any more, a co-worker told me about his experience with Dr Kaloti. After I couldnt take the pain anymore, I finally decided to man up and give their office a call. I needed a ton of work to be done including fillings, crowns and an implant but what really impressed me is that not once did I feel shamed for ignoring my teeth for so many years. For a grown man its not easy to admit my fear but his office handled me so well and his hands are so gentle that you dont even feel the shots. I recommend them highly.</p>
          </div>
          <div class="row">
            <p class="test__date">M Hammond - 25 Nov 2016</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/full-mouth-reconstruction" class="test__service">Dental Implants</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">I totally agree with the other reviews!</p>
          </div>
          <div class="row">
            <p class="test__message">Best dentist experience! He did my implant 3 years ago and I often forget I have it. The procedure itself was easier than having the tooth pulled and 3 years later it looks and chews like all my other teeth</p>
          </div>
          <div class="row">
            <p class="test__date">Aalayyan- 20 Nov 2016</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/gum-lift-procedures" class="test__service">Gum Lift</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">I won't go to anyone else!</p>
          </div>
          <div class="row">
            <p class="test__message">It's such a relief to actually look forward to going to the dentist! Dr. Kaloti sincerely cares about his patients and wants to make sure they are happy with everything about their smile. You can really feel the passion he has for dentistry! Honestly, I drive out of my home city to see him, because I won't go to anyone else.</p>
          </div>
          <div class="row">
            <p class="test__date">KC- 9 Oct 2016</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/full-mouth-reconstruction" class="test__service">Dental Implants</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">I had worn down teeth and spaces between my front teeth</p>
          </div>
          <div class="row">
            <p class="test__message">Had my whole mouth redone with crowns for the old fillings and and implants for the missing teeth. Dr.Kaloti, Stephanie and Penny were amazing! They pretty much held my hand through the whole thing! For a big chicken like me, I felt like I couldn't be in better hands :))</p>
          </div>
          <div class="row">
            <p class="test__date">Suzi - 14 Dec 2016</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/porcelain-veneers" class="test__service">Porcelain Veneers</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Custom Crafted Smile </p>
          </div>
          <div class="row">
            <p class="test__message">Amazing customer service and results. On time and always glad to answer questions and concerns.</p>
          </div>
          <div class="row">
            <p class="test__date">Andre Mali - 7 Jun 2017</p>
          </div>
        </div>
        <div class="test__review_pad">
          <div class="row">
            <div class="col-sm-2 ">
              <img class="test__stars" src={ require("../images/desktop/5-stars.png" )} alt="5 Star Review" />
            </div>
            <div class="col-sm-2 ">
              <a href="/full-mouth-reconstruction" class="test__service">Dental Implants</a>
            </div>
            <div class="col-sm-8 col-ls-6"></div>
          </div>
          <div class="row">
            <p class="test__subject">Modern and Thorough</p>
          </div>
          <div class="row">
            <p class="test__message">Theyre very modern and very thorough. You are seen at your appointment time and they will make your smile look good!!
            </p>
          </div>
          <div class="row">
            <p class="test__date">Jason Jaffet - 18 Oct 2016</p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage